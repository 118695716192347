import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import ContactUsPanel from './components/ContactUsPanel';

import './index.css';

class ContactUsPage extends Component {
  render() {
    return (
      <App displayGettingStartedPanel={false}>
        <div className="component contact-us-page">
          <Helmet title="Contact us – ThinkReservations">
            <meta
              name="description"
              content="Whether you're looking to upgrade your PMS, need support, or want to partner with us, we're just a call or email away!"
            />
          </Helmet>

          <ContactUsPanel />
        </div>
      </App>
    );
  }
}

export default ContactUsPage;
